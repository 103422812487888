var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-grow-1 z-1",attrs:{"id":"google-pay-button"}},[_c('google-pay-button',{staticStyle:{"width":"100%","height":"56px","display":"flex"},attrs:{"environment":_vm.environment,"button-type":"plain","button-color":"black","button-radius":"0","button-size-mode":"fill","existing-payment-method-required":_vm.existingPaymentMethodRequired},domProps:{"paymentRequest":{
            apiVersion: _vm.paymentRequest.apiVersion,
            apiVersionMinor: _vm.paymentRequest.apiVersionMinor,
            allowedPaymentMethods: _vm.paymentRequest.allowedPaymentMethods,
            merchantInfo: _vm.paymentRequest.merchantInfo,
            shippingAddressRequired: true,
            emailRequired: true,
            transactionInfo: {
              totalPriceStatus: 'FINAL',
              totalPriceLabel: _vm.translate('google_pay_total_price_label'),
              totalPrice: String(this.totalAmount),
              currencyCode: this.currencyCode,
              countryCode: this.countryCode
            },
          }},on:{"loadpaymentdata":_vm.onLoadPaymentData,"error":_vm.onError}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }