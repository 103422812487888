<template>
  <div class="container pt-2" data-elbcontext=shopping:cart>
    <LoadingOverlay v-if="threeDSecureLoading"/>
    <TheOpcErrorMessage />
    <div class="row">
      <h3 id="items" class="col-6 col-lg-8 col-xl-9 font-size-xl c-gray my-3 l-spacing-1 font-weight-bold">
        {{ translate('new_cart_page_items_section_title') }}
      </h3>
    </div>
    <div class="row flex-row flex-lg-row-reverse">
      <div class="sidebar col-12 col-lg-4 col-xl-3">
        <div class="row position-sticky">
          <div class="col-12 col-md-8 col-lg-12 pl-0 pr-0 pr-md-3 pr-lg-0">
            <TheCartSummary class="mr-md-3 mr-lg-0 h-100" @showModal="showModal" @hook:mounted="summaryIsMounted"/>
          </div>
          <TheCartAwards v-if="showCartAwards" class="col-4 col-lg-12 mt-lg-2"/>
        </div>
      </div>
      <div class="col-12 col-lg-8 col-xl-9" v-show="summaryFinished">
        <SlideDown>
          <div class="row" v-if="showUpsell && !selectedUpsell">
            <div class="col-12 px-0 px-lg-3 mt-3 mt-lg-0 mb-lg-3">
              <TheCartUpsell />
            </div>
          </div>
        </SlideDown>
        <div class="row">
          <div v-if="showUpsell" class="col-12 mb-3 mt-3 mt-md-0 px-0 px-lg-3">
            <TheCartUpsellItemsSection v-if="selectedUpsell" :product-data="selectedUpsell" :key="selectedUpsell.product_id"/>
          </div>
          <div v-if="itemCount < 1 && isUpdating" class="col-12 px-0 px-lg-3">
            <div class="bg-white border-b mt-2 mt-lg-0 p-3">
              <LoadingOverlay width="40" height="40" class="position-relative"/>
            </div>
          </div>
          <TheCartItemsSection v-else @sectionUpdated="setupCheckout" @sectionRendered="triggerLoad"
                               class="col-12 px-0 px-lg-3 mt-3 mt-lg-0"/>
          <LazyComponent id="wishlist-section" :delay="1000" class="container">
            <TheWishlistSection />
          </LazyComponent>
          <LazyComponent id="cart-teaser" :delay="1000" class="cart-teaser col-12" >
            <CmsBlock identifier="cart_teaser_v2" disable-animation hide-on-error />
          </LazyComponent>
          <LazyComponent v-if="soldOutProducts" id="sold-out-section" :delay="1000" class="col-12 px-0 px-md-3">
            <TheCartSoldOutItemsSection />
          </LazyComponent>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'pinia'
import { useSoldOutProducts } from '@/stores/soldOutProduct'
import { useWishlistStore } from '@/stores/wishlist.js'
import { useUpsellStore } from '@/stores/upsell'
import { useCartStore } from '@/stores/cart'
import { useFeaturesStore } from '@/stores/features'
import { useCheckoutStore } from '@/stores/checkout'

const LazyComponent = () => import('@/components/basic/atoms/LazyComponent.vue')
const SlideDown = () => import('@/components/transitions/SlideDown.vue')
const TheWishlistSection = () => import('@/components/cart/organisms/TheWishlistSection.vue')
const CmsBlock = () => import('@/components/basic/atoms/CmsBlock')
const LoadingOverlay = () => import('@/components/basic/atoms/LoadingOverlay')
const TheCartItemsSection = () => import('@/components/cart/organisms/TheCartItemsSection')
const TheCartSoldOutItemsSection = () => import('@/components/cart/organisms/TheCartSoldOutItemsSection')
const TheCartSummary = () => import('@/components/cart/organisms/TheCartSummary')
const TheCartAwards = () => import('@/components/cart/organisms/TheCartAwards')
const TheCartUpsell = () => import('@/components/cart/organisms/TheCartUpsell')
const TheCartUpsellItemsSection = () => import('@/components/cart/organisms/TheCartUpsellItemsSection')
const TheOpcErrorMessage = () => import('@/components/opc/atoms/TheOpcErrorMessage.vue')

export default {
  name: 'TheCartTemplate',
  components: {
    LoadingOverlay,
    TheCartItemsSection,
    TheCartSummary,
    TheCartAwards,
    TheCartUpsell,
    SlideDown,
    TheCartUpsellItemsSection,
    LazyComponent,
    TheWishlistSection,
    TheCartSoldOutItemsSection,
    CmsBlock,
    TheOpcErrorMessage,
  },
  props: {
    showModal: Function
  },
  data () {
    return {
      canCheckout: false,
      summaryFinished: false
    }
  },
  computed: {
    ...mapState(useUpsellStore, {
      upsellConfig: 'getConfig',
      selectedUpsell: 'selectedUpsell'
    }),
    ...mapState(useSoldOutProducts, { soldOutProducts: 'totalCount' }),
    ...mapState(useWishlistStore, { savedProducts: 'totalCount' }),
    ...mapState(useCartStore, ['itemCount', 'isUpdating']),
    ...mapState(useFeaturesStore, { featureEnabled: 'isFeatureEnabled' }),
    ...mapState(useCheckoutStore, ['threeDSecureLoading']),
    showCartAwards () {
      return this.$root.gridBreakpoint > this.$gridSizes.md ||
        (this.$root.gridBreakpoint > this.$gridSizes.sm && this.canCheckout)
    },
    showUpsell () {
      return this.featureEnabled('cart_upsell') && this.upsellConfig?.is_active
    }
  },
  methods: {
    ...mapActions(useUpsellStore, ['refreshUpsell', 'removeUpsell']),
    ...mapActions(useSoldOutProducts, ['refreshSoldOutProducts']),
    triggerLoad () {
      this.refreshSoldOutProducts()
    },
    summaryIsMounted () {
      setTimeout(() => { this.summaryFinished = true }, 100)
    },
    setupCheckout (canCheckout) {
      this.canCheckout = canCheckout
      this.$emit('setupCheckout', canCheckout)

      this.refreshUpsell()
    }
  },
  watch: {
    async itemCount () {
      await this.refreshUpsell()
      if (this.upsellConfig?.condition_fulfilled || !this.selectedUpsell) return

      const { cart_item_id: cartItemId } = this.selectedUpsell
      await this.removeUpsell({ cartItemId })
    }
  }
}
</script>
<style scoped lang="scss">
.sidebar > div {
  top: 0;
}

.container {
  min-height: 500px;
}

::v-deep .cart-teaser > div {
  margin-top: 1rem;
}
</style>
