import SiteConfig from '@/services/SiteConfig'

const API_ROOT = SiteConfig.apiRoot
const PAGE_ROOT = SiteConfig.pageRoot

export const FILTERS = {
  IN_STOCK_VENDORS: 'in_stock_vendors'
}

export const API_URL = {
  NPS: {
    ANSWER: API_ROOT + 'v2/nps/answer',
    VOTE: API_ROOT + 'v2/nps/vote'
  },
  LOG: {
    PAYMENT_ERROR: API_ROOT + 'v2/log/payment-error',
  },
  DATA_MINING: {
    PRODUCT_VIEW: API_ROOT + 'v1/catalog/products/view',
    PAGE_VIEW: API_ROOT + 'v1/cms/pages/view',
    CATEGORY_VIEW: API_ROOT + 'v1/catalog/categories/view',
    EVENTS: API_ROOT + 'v2/datamining/events',
  },
  AUTH: {
    LOGIN: API_ROOT + 'v2/auth/login',
    TWO_FACTOR_AUTH: API_ROOT + 'v2/auth/verify-two-fa',
    LOGIN_LEGACY: API_ROOT + 'v1/auth/login',
    LOGOUT: API_ROOT + 'v2/auth/logout',
    REFRESH: API_ROOT + 'v2/auth/refresh',
    REGISTER: API_ROOT + 'v2/auth/register',
    RESET: API_ROOT + 'v2/auth/reset'
  },
  CUSTOMER: {
    ADDRESSES: API_ROOT + 'v2/customer/addresses',
    WISHLIST: API_ROOT + 'v2/customer/wishlist',
    SUBSCRIPTIONS: API_ROOT + 'v2/customer/subscriptions',
    PROFILE: API_ROOT + 'v1/customers/profile',
    ORDERS: API_ROOT + 'v1/customers/profile/customers/orders/{0}',
    MILES: 'v1/customers/miles', // GET
    FEATURES: API_ROOT + 'v2/customer/features',
    PAYMENT_METHODS: {
      ADD: API_ROOT + 'v2/customer/payment-methods', // POST
      BRAINTREE_TOKEN: API_ROOT + 'v2/customer/payment-methods/braintree-client-token',
      BRAINTREE_METHOD_NONCE: API_ROOT + 'v2/customer/payment-methods/braintree-method-nonce' // POST
    }
  },
  MANUFACTURER: {
    GET: API_ROOT + 'v2/manufacturer/{0}',
    PRODUCTS: API_ROOT + 'v1/catalog/manufacturer/{0}/products?fields=id,campaign_id,meta_description,name,url,image_logo&'
  },
  CORE: {
    AVAILABLE_PAYMENT_METHODS: API_ROOT + 'v2/core/available-payment-methods'
  },
  ORDERS: {
    PLACE: API_ROOT + 'v2/checkout/orders' // POST
  },
  EXPRESS_ORDERS: {
    PLACE: API_ROOT + 'v2/checkout/express-order' // POST
  },
  CART: {
    GET: API_ROOT + 'v1/checkout/cart/{0}', // GET 0: cartUuid
    GET_V2: API_ROOT + 'v2/cart/{0}', // GET 0: cartUuid
    ITEMS: API_ROOT + 'v1/checkout/cart/{0}/items', // GET, POST 0: cartUuid
    ITEMS_V2: API_ROOT + 'v2/cart/{0}/items', // GET, POST 0: cartUuid
    ITEM: API_ROOT + 'v1/checkout/cart/{0}/items/{1}', // GET, PATCH, DELETE 0: cartUuid 1: cartItemId
    ITEM_V2: API_ROOT + 'v2/cart/{0}/items/{1}', // GET, PATCH, DELETE 0: cartUuid 1: cartItemId
    SOLD_OUT_PRODUCTS: API_ROOT + 'v1/checkout/cart/{0}/sold_out' // GET, 0: cartUuid
  },
  CMS: {
    BLOCK: API_ROOT + 'v1/cms/block/{0}', // GET
    VARIABLE: API_ROOT + 'v2/cms/variables/{0}', // GET
    VARIABLE_BULK: API_ROOT + 'v2/cms/variables?identifiers={0}' // GET
  },
  CATALOG: {
    REVIEW: {
      GET: API_ROOT + 'v1/catalog/reviews?product_id={0}', // GET 0:product_id
      ADD: API_ROOT + 'v1/catalog/reviews', // POST
      UPDATE: API_ROOT + 'v1/catalog/reviews/{0}?{1}', // PATCH 0: review_id 1: site_id
      DELETE: API_ROOT + 'v1/catalog/reviews/{0}?' // DELETE 0: review_id
    },
    PRODUCT: {
      // GET 0: product_id 1: campaign_id 2: fields and embeds: see api docs in platform
      GET: API_ROOT + 'v1/catalog/products/{0}?campaign_id={1}&{2}',
      GET_V2: API_ROOT + 'v2/products/{0}?campaign-id={1}&{2}',
      GET_V2_WITHOUT_CAMPAIGN: API_ROOT + 'v2/products/{0}',
      MILES: API_ROOT + 'v1/catalog/products/miles?product_id={0}&campaign_id}={1}' // GET
    },
    MANUFACTURER: {
      GET: API_ROOT + 'v1/catalog/manufacturers/{0}' // GET 0: manufacturer_id
    },
    TAG: API_ROOT + 'v1/catalog/products/view' // POST
  },
  RECOMMENDATIONS: API_ROOT + 'v2/recommendations',
  TURBO_PASCAL_SORT: API_ROOT + 'v2/turbopascal-sort', // GET
  CONFIG: API_ROOT + 'v1/config/customer-mobile-app' // GET
}
export const REQUEST_URL = {
  REVIEW: {
    VOTE: PAGE_ROOT + 'review/helpful/vote'
  },
  META: {
    PRODUCT_BULK: PAGE_ROOT + 'catalog/meta/product/bulk',
    CUSTOMER_PROFILE: PAGE_ROOT + 'customer/meta/profile/{0}',
    RECENTLY_VIEWED_ITEMS: PAGE_ROOT + 'customer/meta/recently-viewed-items/{0}/{1}/{2}', // GET 0: id_to_ignore 1: limit 2: offset
    CUSTOMER_UTM: PAGE_ROOT + 'customer/meta/utm',
    CUSTOMER_ACCOUNT: PAGE_ROOT + 'meta/account',
    CROSS_SELLS: PAGE_ROOT + 'catalog/meta/cross-sells/{0}/{1}',
    CHECKOUT_COUPON: PAGE_ROOT + 'checkout/meta/coupon',
  }
}

export const PAGE_URL = {
  PROFILE: PAGE_ROOT + 'account/manage/profile',
  LOGIN: PAGE_ROOT + 'login',
  LOGOUT: PAGE_ROOT + 'logout',
  REGISTER: PAGE_ROOT + 'register',
  ORDERS: PAGE_ROOT + 'account/manage/orders',
  REVIEWS: PAGE_ROOT + 'account/manage/reviews',
  SEARCH: PAGE_ROOT + 'search',
  ADMIN: PAGE_ROOT + 'admin',
  FILTER: PAGE_ROOT + '{0}?{1}={2}', // 0: category_page 1: filter_name 2: filter_value
  CART: '/checkout/cart',
  WISHLIST: PAGE_ROOT + 'merkliste'
}

export const PAGE_ANCHORS = {
  CHARACTERISTICS: '#characteristics',
  CROSS_SELLS: 'cross-sells'
}

export const COOKIES = {
  MILES_AND_MORE_ENABLED: 'miles-and-more-enabled'
}

export const PRODUCT_TYPES = {
  SIMPLE: 'simple',
  BUNDLE: 'bundle'
}

export const HTML_EVENT = {
  CLICK: 'click',
  SCROLL: 'scroll',
  MOUSE_ENTER: 'mouseenter',
  MOUSE_LEAVE: 'mouseleave',
  RESIZE: 'resize',
  INPUT: 'input'
}

export const HTML_CUSTOM_ATTRIBUTES = {
  ITEM_LIST_ID: 'item-list-id'
}

export const HTML_CLASS = {
  LOZAD_SELECTOR: 'lozad',
  NO_SCROLL: 'no-scroll'
}

export const CUSTOM_EVENT = {
  CART_USED: 'cartUsed'
}

// https://developers.google.com/gtagjs/reference/ga4-events
export const GA4_OFFICIAL_EVENT = {
  ADD_TO_CART: 'add_to_cart',
  REMOVE_FROM_CART: 'remove_from_cart',
  SELECT_ITEM: 'select_item',
  ADD_TO_WISHLIST: 'add_to_wishlist',
  VIEW_CART: 'view_cart',
  VIEW_ITEM: 'view_item',
  VIEW_ITEM_LIST: 'view_item_list',
  VIEW_PROMOTION: 'view_promotion',
  SELECT_PROMOTION: 'select_promotion',
  SELECT_CONTENT: 'select_content'
}

export const GA4_CUSTOM_EVENT = {
  CHANGE_QUANTITY: 'change_quantity',
  CHANGE_PAGE_TITLE: 'page_title_updated',
  VIEW_CONTENT: 'view_content',
  USER_DATA: 'user_data',
  REMOVE_FROM_WISHLIST: 'remove_from_wishlist',
  SLIDER: 'slider',
}

export const MATOMO_EVENT = {
  CART_UPDATE: 'update_cart'
}

export const EXPRESS_CHECKOUT_ON_CART_REGEX = /checkout\/cart/
