import { defineStore } from 'pinia'

const HTTP_CUSTOMER_EXISTS = 409
const ADDRESS_TYPE = {
  SHIPPING: 'shippingAddress',
  BILLING: 'billingAddress'
}

let savedAddress = { [ADDRESS_TYPE.SHIPPING]: null, [ADDRESS_TYPE.BILLING]: null }

export const useCheckoutStore = defineStore('checkout', {
  state () {
    return {
      payment: {
        inProgress: false,
        availableMethods: [],
        error: true,
        data: {},
        selectedMethod: ''
      },
      customer: {
        [ADDRESS_TYPE.SHIPPING]: { ref: null, customer_address_id: null },
        [ADDRESS_TYPE.BILLING]: { ref: null, customer_address_id: null },
        shippingAge: { ref: null, day: null, month: null, year: null },
        differentBillingAddress: { value: false },
        email: { ref: null, value: null },
        agreements: { ref: null, agb: null, newsletter: null },
        payment: { ref: null, id: null, tokenized_nonce: null },
        trackingCouponCode: { ref: null, value: null }
      },
      selectedQty: 1,
      grandTotal: 1,
      product: {
        product_id: null,
        campaign_id: null,
        params: {},
      },
      backendError: '',
      pendingOrder: false,
      formsValid: false,
      inErrorState: false,
      isExpressCheckout: false,
      threeDSecureLoading: false,
    }
  },
   getters: {
    selectedPaymentMethod: ({ payment: { selectedMethod } }) => selectedMethod,
    paymentError: ({ payment: { error } }) => error,
    paymentMethods: ({ payment: { availableMethods } }) => availableMethods,
    paymentInProgress: ({ payment: { inProgress } }) => inProgress,
    isInErrorState: ({ inErrorState }) => inErrorState
  },
  actions: {
    replaceBackendError ({ e, message } = { message: '' }) {
      this.backendError = message
      this.inErrorState = message !== '' || !!e
      if (!e) {
        return
      }
      console.error(e)
    },
    set3DSecureLoading (value) {
      this.threeDSecureLoading = value
    },
    setExpressCheckout (value) {
      this.isExpressCheckout = value
    },
    async placeOrder (product, selectedQty, grandTotal, validateForm = true) {
      this.replaceBackendError()

      this.selectedQty = selectedQty ?? this.selectedQty
      this.grandTotal = grandTotal ?? this.grandTotal
      this.product = product ?? this.product

      if (validateForm) {
        this.validateForms()
        if (!this.formsValid) return
      }
      this.pendingOrder = true

      const { UtilityHelper, TranslationsHelper } = await _getModules()

      const hookParams = [product, selectedQty, grandTotal, validateForm]

      if (!await _getCustomer(this.customer, this.placeOrder, hookParams)) {
        this.pendingOrder = false
        return
      }

      if (await this.saveAddresses() && await this.savePaymentMethod()) {
        try {
          void _subscribeNewsletter(this.customer.agreements.newsletter)
          const uuid = await _createCartWithProduct(this.product, this.selectedQty)
          const { redirectUrl } = await _placeOrder({ customer: this.customer, uuid, product: this.product, selectedMethod: this.payment.selectedMethod })

          location.replace(redirectUrl)
          return
        } catch (e) {
          this.setPaymentInProgress(false)
          if (e.status === 400) {
            this.replaceBackendError({
              e,
              message: UtilityHelper.formatString(
                TranslationsHelper.translate('error_message_payment_not_available'),
                e.cause
              )
            })
          } else {
            this.replaceBackendError({ e, message: 'create_order_error' })
          }
          console.error(e)
          UtilityHelper.noticeNRError(e, 'Failed to place order: ')
        }
      }
      this.setPaymentInProgress(false)
      this.pendingOrder = false
    },
    async placeExpressOrder(expressPayload, bin) {
      this.replaceBackendError()
      this.pendingOrder = true

      const { UtilityHelper, TranslationsHelper } = await _getModules()

     if (expressPayload.paymentMethod.method_code === 'google_pay') {
      const { paymentMethod, email, shippingAddress, billingAddress } = expressPayload

      this.customer = {
        ...this.customer,
        email,
        shippingAddress,
        billingAddress,
        payment: paymentMethod
      }
      this.payment = {
        ...this.payment,
        selectedMethod: paymentMethod.method_code,
        data: {
          nonce: paymentMethod.tokenized_nonce,
          details: {
            isNetworkTokenized: false,
            bin: bin
          }
        }
      }

      try {
           if (await this.savePaymentMethod()) {
             const threeDsTokenizedNonce = this.payment.data.nonce
             expressPayload.braintreeNonce = threeDsTokenizedNonce
             expressPayload.paymentMethod.tokenized_nonce = threeDsTokenizedNonce
           } else {
             this.setPaymentInProgress(false)
             this.replaceBackendError({ message: 'create_order_error' })
             return
           }
      } catch (e) {
          this.setPaymentInProgress(false)
          this.replaceBackendError({ e, message: 'create_order_error' })
          return
      }
    }

      try {
        const { redirectUrl } = await _placeExpressOrder(expressPayload)
        location.replace(redirectUrl)
        return
      } catch (e) {
        this.setPaymentInProgress(false)
        if (e.status === 400) {
          this.replaceBackendError({
            e,
            message: UtilityHelper.formatString(
              TranslationsHelper.translate('error_message_payment_not_available'),
              e.cause
            )
          })
        } else {
          this.replaceBackendError({ e, message: 'create_order_error' })
        }
        console.error(e)
        UtilityHelper.noticeNRError(e, 'Failed to place order: ')
      }
      this.setPaymentInProgress(false)
      this.pendingOrder = false
    },

    validateForms(highlight = true, isRegularCheckout = false) {
      // map representing the precedence of the elements
      const { shippingAddress, billingAddress, shippingAge, email, agreements, payment, trackingCouponCode } = this.customer
      const validationMap = [shippingAddress, billingAddress, email, shippingAge, payment, trackingCouponCode, agreements]

      const getOffset = (headerId, stepperId) => {
        const headerHeight = document.getElementById(headerId)?.offsetHeight || 0
        const stepperHeight = document.getElementById(stepperId)?.offsetHeight || 5
      return headerHeight + stepperHeight
      }

      let offset = null

      if (!isRegularCheckout) {
        offset = getOffset('opc-header', 'opc-stepper')
      } else {
        offset = getOffset('checkout-header', 'checkout-stepper')
      }

      let firstError = null

      validationMap.forEach(({ ref }) => {
        if (ref?.validate() ?? true) return

        firstError = firstError ?? ref

        if (highlight) return
        ref?.resetValidation()
      })

      this.formsValid = !firstError

      if (!(highlight && firstError)) return

      setTimeout(async () => {
        const { $el: element } = firstError
        const { DomHelper } = await _getModules()

        if(DomHelper.elementInViewport(element, offset)) return

        DomHelper.scrollIntoView(element, offset)
      }, 0)
    },
    async refreshPaymentMethods () {
      const { ApiController } = await _getModules()
      this.payment.availableMethods = _sortPaymentMethods(await ApiController.fetchAvailablePaymentMethods())
    },
    async savePaymentMethod () {
      this.setPaymentInProgress(true)
      try {
        const verification = await _verifyPayment(this.payment)
        const data = await _saveAndSecurePayment(
          {
            selectedQty: this.selectedQty,
            calculated: this.calculated,
            customer: this.customer,
            payment: this.payment,
            grandTotal: this.grandTotal,
          },
          verification)

        if(this.payment.selectedMethod === 'google_pay') {
          this.payment.data = { ...this.payment.data, nonce: data.tokenized_nonce }
        }

        this.setCustomerProperty({ type: 'payment', data })
        this.setPaymentInProgress(false)

        return true
      } catch (e) {
        this.setPaymentInProgress(false)

        const { ApiController } = await _getModules()

        try {
          await ApiController.logPaymentError(this.payment.selectedMethod, e)
        } catch (e) {
          console.error(e)
        }

        this.replaceBackendError({ e, message: 'checkout_payment_challenge_error' })

        return false
      }
    },
    async setSelectedPaymentMethod (method) {
      this.payment.selectedMethod = method
      this.payment.error = null
      this.payment.data = {}
      this.validateForms(false)
    },
    async setCustomerProperty({ type, data }) {
      const previous = this.customer[type] ?? {}
      this.customer[type] = { ...previous, ...data }
    },
    setPaymentInProgress (value) {
      this.payment.inProgress = value
    },
    updatePaymentData ({ error, data }) {
      this.payment.error = error ?? null
      this.payment.data = data ?? {}
      this.validateForms(false)
    },
    updateCustomerInfo (payload) {
      this.setCustomerProperty(payload)
      this.validateForms(false)
    },
    async getAddresses () {
      const { ApiController, customerStore } = await _getModules()
      await customerStore.waitUntilPending()

      if (!customerStore.customerActive) return

      const addresses = await ApiController.fetchCustomerAddresses()

      const shipping = addresses.find(address => address.is_default_shipping_address)
      const billing = addresses.find(address => address.is_default_billing_address) ?? null

      this.setCustomerProperty({ type: ADDRESS_TYPE.SHIPPING, data: shipping })
      this.setCustomerProperty({ type: ADDRESS_TYPE.BILLING, data: billing })

      // stash for later
      savedAddress = { [ADDRESS_TYPE.SHIPPING]: shipping, [ADDRESS_TYPE.BILLING]: billing }
    },
    async saveAddresses () {
      try {
        const dob = _getDob(this.customer)
        if (!this.customer.differentBillingAddress.value || _addressesAreTheSame(this.customer[ADDRESS_TYPE.SHIPPING], this.customer[ADDRESS_TYPE.BILLING])) {
          const shippingAddressId = await _saveAddress(this.customer.shippingAddress, dob, ADDRESS_TYPE.SHIPPING)
          this.setCustomerProperty({ type: ADDRESS_TYPE.SHIPPING, data: shippingAddressId })

          return true
        }

        const [shippingAddressId, billingAddressId] = await Promise.all([
          await _saveAddress(this.customer.shippingAddress, dob, ADDRESS_TYPE.SHIPPING),
          await _saveAddress(this.customer.billingAddress, dob, ADDRESS_TYPE.BILLING)
        ])
        this.setCustomerProperty({ type: ADDRESS_TYPE.SHIPPING, data: shippingAddressId })
        this.setCustomerProperty({ type: ADDRESS_TYPE.BILLING, data: billingAddressId })

        return true
      } catch (e) {
        this.replaceBackendError({ e, message: 'save_address_error' })

        return false
      }
    },
  }
})

const _placeOrder = async ({ customer, uuid, product, selectedMethod }) => {
  const { ApiController, TrackingHelper } = await _getModules()
  const { payment, shippingAddress, billingAddress, differentBillingAddress: { value: different }, trackingCouponCode } = customer
  const payload = {
    cart_uuid: uuid,
    shipping_address_id: shippingAddress.customer_address_id,
    billing_address_id: different && billingAddress.customer_address_id ? billingAddress.customer_address_id : shippingAddress.customer_address_id,
    payment_method_id: payment.id,
    payment_method: selectedMethod,
    braintree_nonce: payment.tokenized_nonce,
    tracking_coupon_code: trackingCouponCode.value ?? null,
  }

  return await ApiController.placeOrder(payload, await TrackingHelper.getUtmParams(product.params))
}

const _placeExpressOrder = async (expressPayload) => {
  const { ApiController } = await _getModules()

  const payload = {
    cart_uuid: expressPayload.cartUuid,
    shipping_address: expressPayload.shippingAddress,
    billing_address: expressPayload.billingAddress,
    email: expressPayload.email,
    payment_method: expressPayload.paymentMethod,
    braintree_nonce: expressPayload.braintreeNonce,
    coupon_code: expressPayload.couponCode,
    use_credits: expressPayload.useCredits
  }

  return await ApiController.placeExpressOrder(payload)
}

const _verifyPayment = async ({ selectedMethod, data: paymentData }) => {
  switch (selectedMethod) {
    case 'paypal':
      return { tokenized_nonce: paymentData.tokenized_nonce }
    case 'credit_card':
      return { tokenized_nonce: paymentData.nonce }
    case 'google_pay':
      return { tokenized_nonce: paymentData.nonce }
    default:
      return paymentData
  }
}

const _saveAndSecurePayment = async (
  { grandTotal, customer, payment: { data: paymentData, selectedMethod } },
  verification
) => {
    const { threeDSecureCheck, ApiController } = await _getModules()

    let data = {}
    let nonce = paymentData.nonce
    let ipAddress = paymentData.ipAddress
    const payload = {
          method_code: selectedMethod,
          is_default_payment_method: false,
          ...verification
        }

    if (selectedMethod !== 'google_pay') {
      const { data: responseData } = await ApiController.savePaymentMethod(payload)
      data = responseData
      if (selectedMethod !== 'credit_card') {
        return { ...data, ...verification }
      }
      // credit card, 3DS
      let { data: { braintree_method_nonce, ip_address } } = await ApiController.fetchBraintreeMethodNonce({ token: data.token })
      nonce = braintree_method_nonce
      ipAddress = ip_address
    }

    if (paymentData.details.isNetworkTokenized) {
      return { ...data, tokenized_nonce: paymentData.nonce }
    }

    const { shippingAddress, differentBillingAddress: { value: different }, email: { value: email } } = customer
    const billingAddress = different ? customer.billingAddress : shippingAddress

    const { nonce: tokenizedNonce } = await threeDSecureCheck({
      shippingAddress,
      billingAddress,
      nonce,
      email,
      amount: grandTotal,
      bin: paymentData.details.bin,
      ipAddress
    })
    return { ...data, tokenized_nonce: tokenizedNonce }
}

const _createCartWithProduct = async (product, qty) => {
  const { ApiController, UtilityHelper } = await _getModules()

  const uuid = UtilityHelper.uuid()
  const payload = { product_id: product.product_id, campaign_id: product.campaign_id, qty }

  await ApiController.addItemToCart(uuid, payload)
  return uuid
}

const _sortPaymentMethods = (methods) => {
  const purchaseOrder = 'purchase_order'
  const filtered = methods.filter(item => item !== purchaseOrder)

  if (filtered.length === methods.length ) return methods

  return [purchaseOrder, ...filtered]
}

const _getCustomer = async (customer, hook, hookParams) => {
  const { UtilityHelper, customerStore, MessageHelper } = await _getModules()
  if (!customerStore.customerActive) {
    try {
      const address = customer.differentBillingAddress.value ? customer.billingAddress : customer.shippingAddress
      await customerStore.register(
        {
          email: customer.email.value,
          firstName: address.first_name,
          lastName: address.last_name,
          gender: address.gender,
          title: address.title,
          dob: _getDob(customer)
        }
      )
    } catch (e) {
      if (e.status === HTTP_CUSTOMER_EXISTS) {
        customerStore.showLogin({ visible: true, email: customer.email.value, hook, hookParams })
        return
      }
      console.error(e)
      UtilityHelper.noticeNRError(e, 'Failed to register customer during checkout: ')
      MessageHelper.addErrorMessage('validation_msg_email')
    }
  }

  return customerStore.customerActive
}

const _saveAddress = async ({ customer_address_id: id, ...address }, dob, type = false) => {
  const original = savedAddress[type]

  if (id && _addressesAreTheSame(original, address)) return id

  const { ApiController } = await _getModules()
  const data = {
    first_name: address.first_name,
    last_name: address.last_name,
    city: address.city,
    country: address.country,
    company: address.company,
    zip: address.zip,
    gender: address.gender,
    street_name: address.street_name,
    street_number: address.street_number,
    dob: dob,
    is_default_shipping_address: 0,
    is_default_billing_address: 0
  }
  return await ApiController.saveCustomerAddress(data)
}

const _addressesAreTheSame = (addr1, addr2) => {
  if (!addr1 || !addr2) return false
  const keys = Object.keys(addr1).filter(key => key !== 'ref' && key !== 'customer_address_id')

  return keys.every(key => addr1[key] === addr2[key])
}

const _subscribeNewsletter = async (newsletterAccepted) => {
  if (!newsletterAccepted) return
  const { ApiController } = await _getModules()

  await ApiController.subscribeNewsletter()
}

const _getDob = ({ shippingAge: { day, month, year } }) => {
  return day && month && year ? `${year}-${month}-${day}` : null
}

const _getModules = (() => {
  let cache

  return async () => {
    if (cache) return cache

    const [
      { default: ApiController },
      { default: TrackingHelper },
      { threeDSecureCheck },
      { default: UtilityHelper },
      { default: TranslationsHelper },
      { default: MessageHelper },
      { default: DomHelper },
      { useCustomerStore }
    ] = await Promise.all([
      await import('@/services/ApiController'),
      await import('@/services/helpers/TrackingHelper'),
      await import('@/services/Braintree'),
      await import('@/services/helpers/UtilityHelper'),
      await import('@/services/helpers/TranslationsHelper'),
      await import('@/services/helpers/MessageHelper'),
      await import('@/services/helpers/DomHelper'),
      await import('@/stores/customer'),
    ])

    cache = {
      ApiController,
      TrackingHelper,
      threeDSecureCheck,
      UtilityHelper,
      TranslationsHelper,
      MessageHelper,
      DomHelper,
      customerStore: useCustomerStore()
    }
    return cache
  }
})()
