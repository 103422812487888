<template>
  <div class="flex-grow-1">
    <VBtn v-show="showApplePayButton" id="apple-pay-button" />
    <LoadingOverlay v-show="paymentInProgress" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useOpcStore } from '@/stores/opc'
import { useCheckoutStore } from '@/stores/checkout'
import { useCustomerStore } from '@/stores/customer'
import { useCartStore } from '@/stores/cart'
import { EXPRESS_CHECKOUT_ON_CART_REGEX } from '@/constants/GlobalConstants'

const LoadingOverlay = () => import('@/components/basic/atoms/LoadingOverlay')

const _getModules = (() => {
  let cache = null

  return async () => {
    if (!cache) {
      const { useApplePay } = await import('@/services/helpers/ApplePayHelper')
      const { default: TrackingHelper } = await import('@/services/helpers/TrackingHelper')

      cache = { 
        useApplePay, 
        TrackingHelper
      }
    }
    return cache
  }
})()

export default {
  name: 'BraintreeApplePayButton',
  components: { LoadingOverlay },
  computed: {
    ...mapState(useOpcStore, ['grandTotal', 'product', 'selectedQty']),
    ...mapState(useCheckoutStore, ['paymentInProgress']),
    ...mapState(useCustomerStore, ['customerProfile']),
    ...mapState(useCartStore, ['cartInstance','creditsAreUsed']),
    showApplePayButton () {
      return  !this.paymentInProgress || this.isExpressCheckoutOnCart
    },
    isExpressCheckoutOnCart() {
      return EXPRESS_CHECKOUT_ON_CART_REGEX.test(location.pathname)
    }
  },
  methods: {
    ...mapActions(useCheckoutStore, [
      'placeOrder',
      'placeExpressOrder',
      'updatePaymentData',
      'updateCustomerInfo',
      'replaceBackendError',
      'setExpressCheckout'
    ]),
    ...mapActions(useOpcStore, ['setLoading']),
    isApplePaySupported () {
      // eslint-disable-next-line no-undef
      return window.ApplePaySession && ApplePaySession.supportsVersion(3) && ApplePaySession.canMakePayments()
    },

    async handleApplePayClick () {
      this.setLoading(true)
    },

    async handleApplePayCancel () {
      this.setLoading(false)
    },

    async handleApplePayApprove (nonce, shippingAddress, billingAddress) {
      this.setExpressCheckout(true)

      const extractStreetNumber = (address) => {
        const match = address.match(/(\d+)/)
        return match ? match[0] : ''
      }
      const mapAddressFields = (address) => ({
        first_name: address.givenName,
        last_name: address.familyName,
        gender: null,
        city: address.locality,
        country: address.countryCode.toLowerCase(),
        company: '',
        zip: address.postalCode,
        street_name: address.addressLines[0].replace(/\d+/g, '').trim(),
        street_number: extractStreetNumber(address.addressLines[0]),
        additional: address.addressLines[1] === undefined ? null : address.addressLines[1]
      })

      const customerShippingAddress = mapAddressFields(shippingAddress)
      const customerBillingAddress = mapAddressFields(billingAddress)

      if (customerBillingAddress.street_number === '' || customerShippingAddress.street_number === '') {
        this.replaceBackendError({ message: 'street_number_error' })
        this.setLoading(false)
        return
      }

      const data = {
        tokenized_nonce: nonce,
        method_code: 'apple_pay',
        is_default_payment_method: false
      }

      if (!this.customerProfile.email) {
        await this.updateCustomerInfo({ type: 'email', data: { value: shippingAddress.emailAddress } })
      }

      await this.updatePaymentData({ data })

      await this.updateCustomerInfo({ type: 'shippingAddress', data:  customerShippingAddress })
      
      const addressesAreSame = JSON.stringify(customerShippingAddress) === JSON.stringify(customerBillingAddress)
      if (!addressesAreSame) {
        await this.updateCustomerInfo({ type: 'billingAddress', data: customerBillingAddress })
        await this.updateCustomerInfo({ type: 'differentBillingAddress', data: { value: true } })
      }

      if(this.isExpressCheckoutOnCart) {

        const { TrackingHelper } = await _getModules()

        const [utmData] = await Promise.all([ 
          await TrackingHelper.getUtmParams(),
        ])
        const expressPayload = {
          cartUuid: this.cartInstance.id,
          shippingAddress: {
            ...customerShippingAddress,
            // values are not available from express payments
            is_default_shipping_address: 0,
            is_default_billing_address: 0
          },
          billingAddress:  {
            ...customerBillingAddress,
            is_default_shipping_address: 0,
            is_default_billing_address: 0
          },
          email: shippingAddress.emailAddress,
          paymentMethod: { 
            method_code: data.method_code,
            is_default_payment_method: data.is_default_payment_method,
            tokenized_nonce: data.tokenized_nonce
          },
          utm: utmData,
          couponCode: this.cartInstance.discounts[0] ? this.cartInstance.discounts[0].coupon_code : null,
          braintreeNonce: data.tokenized_nonce,
          useCredits: this.creditsAreUsed,
        }
        
        await this.placeExpressOrder(expressPayload)

        this.setLoading(false)
        return
      }

      await this.placeOrder(this.product, this.selectedQty, this.grandTotal, false)
      this.setLoading(false)
    },

    handleApplePayError () {
      this.updatePaymentData({ error: true })
      this.setPaymentInProgress(false)
      this.setLoading(false)
    },

    async initializeApplePay () {
      const handlers = {
        onApprove: this.handleApplePayApprove,
        onError: this.handleApplePayError,
        onClick: this.handleApplePayClick,
        onCancel: this.handleApplePayCancel,
        dataCollector: this.handleDataCollector,
        getGrandTotal: this.getGrandTotal
      }
      try {
        const { useApplePay } = await _getModules()
        await useApplePay(handlers)
      } catch (error) {
        console.error('Failed to load Apple Pay module', error)
        this.removeApplePayButton()
      }
    },

    removeApplePayButton () {
      const applePayContainer = document.getElementById('apple-pay-container')
      if (applePayContainer) {
        applePayContainer.remove()
        const googlePayButton = document.getElementById('google-pay-button')
        if (!googlePayButton) {
          const expressCheckouts = document.getElementById('express-checkouts')
          expressCheckouts.remove()
        }
      }
    },
    handleDataCollector (data) {
      this.deviceData = JSON.parse(data)
    },
    getGrandTotal () {
      return this.isExpressCheckoutOnCart ? this.cartInstance.grandTotal : this.grandTotal
    }
  },
  async mounted () {
    await this.$nextTick()
    if (this.isApplePaySupported()) {
      await this.initializeApplePay()
    } else {
      this.removeApplePayButton()
    }
  },
}
</script>

<style lang="scss" scoped>
#apple-pay-button {
  border-radius: 0;
  display: flex;
  width: 100%;
  height: 56px;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: plain;
  -apple-pay-button-style: black;
  appearance: -apple-pay-button;
}
</style>
